import { Injectable, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable()
export class GlobalService {
  params: any;
  imageUrl = "";
  public basePath = "";
  public loggedIn = false;
  public basePathImage: string;
  image = "";
  header: any;
  headerWithFormData: any;
  constructor(public http: HttpClient, public router: Router) {
    // this.basePath = "http://localhost:3000/api/";
    // this.basePathImage = "http://localhost:3000/api/";
    this.basePath = 'https://api.indianarchery.info/api/';
    this.basePathImage = "https://api.indianarchery.info/uploads/";

    // this.basePath = 'http://localhost:3000/api/';
    // this.basePathImage = 'http://localhost:3000/public/images/'
    // this.basePathImage = 'https://aai.psacademy.in/';
  }

  public base_path_api() {
    return this.basePath;
  }
  setHeader() {
    if (localStorage.getItem("userId")) {
      const token: any = localStorage.getItem("token");
      this.header = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + token);
    } else {
      this.header = new HttpHeaders().set("Content-Type", "application/json");
    }
  }

  setHeaderNew() {
    console.log(localStorage.getItem("userId"))
    const token: any = localStorage.getItem("token");
    this.headerWithFormData = new HttpHeaders()
      .set("Authorization", "Bearer " + token);
  }

  public PostRequest(url: string, data: any): any {
    this.setHeader();
    return this.http
      .post(url, data, {
        headers: this.header,
        params: this.params,
      })
      .pipe(
        map((res: any) => {
          return [{ json: res }];
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public PostRequestWithFormData(url: string, data: any): any {

    this.setHeaderNew();
    const token: any = localStorage.getItem("token");
    return this.http
      .post(url, data, {
        headers: this.headerWithFormData,
        params: this.params,

      })
      .pipe(
        map((res: any) => {
          return [{ json: res }];
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public PostRequestUnauthorised(url: string, data: any): any {
    this.setHeader();
    return this.http
      .post(url, data, {
        headers: this.header,
        params: this.params,
      })
      .pipe(
        map((res: any) => {
          return [{ json: res }];
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public GetRequest(url: string): any {
    this.setHeader();
    return this.http
      .get(url, {
        headers: this.header,
        params: this.params,
      })
      .pipe(
        map((res: any) => {
          return [{ json: res }];
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public GetRequestUnauthorised(url: string): any {
    this.setHeader();
    return this.http
      .get(url, {
        headers: this.header,
        params: this.params,
      })
      .pipe(
        map((res: any) => {
          return [{ json: res }];
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public DeleteRequest(url: string): any {
    this.setHeader();
    return this.http
      .delete(url, {
        headers: this.header,
        params: this.params,
      })
      .pipe(
        map((res: any) => {
          if (res) {
            return [{ json: res }];
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public PutRequest(url: string, data: any): any {
    this.setHeader();
    return this.http
      .put(url, data, {
        headers: this.header,
        params: this.params,
      })
      .pipe(
        map((res: any) => {
          // if (res) {
          // }
          return [{ json: res }];
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public Deleterequestnew(url: string, data: any): any {
    this.setHeader();
    const httpOptions = {
      headers: this.header,
      body: data,
      params: this.params,
    };
    return this.http.delete(url, httpOptions).pipe(
      map((res: any) => {
        return [{ json: res }];
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
